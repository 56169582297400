import { styled, Typography } from "@gannettdigital/community-hub-components";
import CircularProgress from "@mui/material/CircularProgress";

export const PageContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),

  [theme.breakpoints.up("sm")]: {
    marginTop: theme.spacing(4)
  }
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontStyle: "italic",
  lineHeight: "26px",
  marginBottom: theme.spacing(1),
  fontSize: 22,

  [theme.breakpoints.up("sm")]: {
    marginBottom: theme.spacing(2),
    fontSize: 32
  }
}));

export const ContentContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  flexDirection: "column"
});

export const Subtitle = styled("div")(({ theme }) => ({
  ...theme.typography.body1,
  display: "flex",
  textAlign: "center",
  marginBottom: theme.spacing(3),
  fontSize: 14,
  lineHeight: "28px",

  [theme.breakpoints.up("sm")]: {
    fontSize: 18
  }
}));

export const SubtitleButton = styled(Subtitle)(({ theme }) => ({
  color: theme.palette.all.blue5,
  fontWeight: "bold",
  cursor: "pointer"
}));

export const Loader = styled(CircularProgress)(({ theme }) => ({
  marginTop: theme.spacing(10),
  marginBottom: theme.spacing(10)
}));
