import { PostedOnline, Publishers } from "@/components/atoms";
import { IObituaryTagFragment } from "@/graphql/fragments/__generated__/obituaryTag.types";
import { Box, Grid } from "@mui/material";

interface IProps {
  publishDate: Date;
  tags?: IObituaryTagFragment[];
}

export const PublishedText = ({ publishDate, tags }: IProps) => {
  return (
    <Box mb={4}>
      <Grid
        container
        alignItems="center"
        flexDirection={{ xs: "column", md: "row" }}
        flexWrap={{ md: "nowrap" }}
      >
        <Grid
          item
          xs={12}
          textAlign={{ xs: "center", md: "left" }}
          mb={{ xs: 2, md: 0 }}
        >
          <PostedOnline publishDate={publishDate} />
          {tags && <Publishers tags={tags} />}
        </Grid>
      </Grid>
    </Box>
  );
};
