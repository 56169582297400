import { IObituaryFragment } from "@/graphql/fragments/__generated__/obituary.types";
import { styled } from "@gannettdigital/community-hub-components";
import { Box } from "@mui/material";
import { CTAPlantTree } from "./CTAPlantTree";
import { CTAViewMemoryBoard } from "./CTAViewMemoryBoard";

interface CTABoxProps {
  obituary: IObituaryFragment;
  treePlantingUrl?: string;
  hasMemories?: boolean;
}

export const CTABox = ({
  obituary,
  treePlantingUrl,
  hasMemories,
}: CTABoxProps) => {
  return (
    <Container>
      {hasMemories && (
        <Box border="1px solid" borderColor="rgba(0,0,0,0.2)" px={2} py={1.5}>
          <CTAViewMemoryBoard obituary={obituary} hasMemories={hasMemories} />
        </Box>
      )}

      {treePlantingUrl && (
        <Box border="1px solid" borderColor="rgba(0,0,0,0.2)" px={2} py={1.5}>
          <CTAPlantTree treePlantingUrl={treePlantingUrl} />
        </Box>
      )}
    </Container>
  );
};

const Container = styled("div")(({ theme }) => ({
  paddingBottom: theme.spacing(4),
  display: "grid",
  gridAutoFlow: "column",
  gridAutoColumns: "1fr",
  gap: theme.spacing(3),
}));
