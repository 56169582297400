import styled from "@/components/contexts/ThemeProvider/styled";
import { IObituaryFragment } from "@/graphql/fragments/__generated__/obituary.types";
import { Grid, GridProps } from "@mui/material";
import { useMemo } from "react";
import { FuneralHomeLogos } from "./FuneralHomeLogos";
import { ObitExternalLink } from "./ObitExternalLink";
import { Paragraphs } from "./Paragraphs";
import { PublishedText } from "./PublishedText";

interface BodyProps {
  obituary: IObituaryFragment;
}

export const Body = ({ obituary }: BodyProps) => {
  const obit = obituary?.historical_obituary;
  const funeralHomeLogos = obit?.logos;
  const siteCode =
    obituary?.obituary_publications?.[0]?.publication.site_code || null;

  const obitSplitInParagraphs = useMemo(() => {
    return (obit?.body || "").split("\n");
  }, [obit?.body]);

  return (
    <Root>
      {obit?.funeralHome?.name && (
        <FuneralHomeGrid container hasPhoto={obituary.images.length > 0} />
      )}

      <Paragraphs paragraphs={obitSplitInParagraphs} />

      {siteCode && funeralHomeLogos && (
        <FuneralHomeLogos logos={funeralHomeLogos} siteCode={siteCode} />
      )}

      {obituary.external_links.map((link) => {
        return <ObitExternalLink key={link.uri} {...link} />;
      })}

      <PublishedText
        publishDate={obit?.external_published_at}
        tags={obituary.obituary_tags}
      />
    </Root>
  );
};

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  minHeight: 300,

  [theme.breakpoints.down("md")]: {
    padding: `0 ${theme.spacing(2)}`,
  },
}));

interface FuneralHomeProps extends GridProps {
  hasPhoto?: boolean;
}

const FuneralHomeGrid = styled(
  (props: FuneralHomeProps) => <Grid {...props} />,
  { shouldForwardProp: (prop) => prop !== "hasPhoto" }
)(({ theme, hasPhoto }) => ({
  [theme.breakpoints.up("md")]: {
    justifyContent: hasPhoto ? "flex-start" : "center",
  },
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
  },
}));
