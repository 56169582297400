// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IGetDisplayBySlugQueryVariables = Types.Exact<{
  before?: Types.Maybe<Types.Scalars['timestamp']>;
  id: Types.Scalars['uuid'];
  offset?: Types.Maybe<Types.Scalars['Int']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type IGetDisplayBySlugQuery = (
  { __typename?: 'query_root' }
  & { display_obituary: Array<(
    { __typename?: 'display_obituary' }
    & Pick<Types.IDisplay_Obituary, 'last_name'>
    & { publish_date: Types.IDisplay_Obituary['source_published_at'], slug: Types.IDisplay_Obituary['source_slug'] }
  )> }
);


export const GetDisplayBySlugDocument = gql`
    query getDisplayBySlug($before: timestamp, $id: uuid!, $offset: Int, $limit: Int) {
  display_obituary(
    limit: $limit
    offset: $offset
    where: {display_obituary_publications: {publication_id: {_eq: $id}}, source_published_at: {_lte: $before}}
    order_by: [{source_published_at: desc_nulls_last}, {last_name: asc}]
  ) {
    last_name
    publish_date: source_published_at
    slug: source_slug
  }
}
    `;

/**
 * __useGetDisplayBySlugQuery__
 *
 * To run a query within a React component, call `useGetDisplayBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisplayBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisplayBySlugQuery({
 *   variables: {
 *      before: // value for 'before'
 *      id: // value for 'id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDisplayBySlugQuery(baseOptions: Apollo.QueryHookOptions<IGetDisplayBySlugQuery, IGetDisplayBySlugQueryVariables>) {
        return Apollo.useQuery<IGetDisplayBySlugQuery, IGetDisplayBySlugQueryVariables>(GetDisplayBySlugDocument, baseOptions);
      }
export function useGetDisplayBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetDisplayBySlugQuery, IGetDisplayBySlugQueryVariables>) {
          return Apollo.useLazyQuery<IGetDisplayBySlugQuery, IGetDisplayBySlugQueryVariables>(GetDisplayBySlugDocument, baseOptions);
        }
export type GetDisplayBySlugQueryHookResult = ReturnType<typeof useGetDisplayBySlugQuery>;
export type GetDisplayBySlugLazyQueryHookResult = ReturnType<typeof useGetDisplayBySlugLazyQuery>;
export type GetDisplayBySlugQueryResult = Apollo.QueryResult<IGetDisplayBySlugQuery, IGetDisplayBySlugQueryVariables>;