import { NoPicturesView } from "@/components/organisms";
import useAuth from "@/hooks/useAuth";
import {
  Button,
  Drawer,
  DrawerContent,
  Typography,
  StyledDropzone,
} from "@gannettdigital/community-hub-components";
import { React } from "@ungap/global-this";
import { useState } from "react";
import {
  ContentContainer,
  PageContainer,
  Title,
} from "./LifeInPicturesPage.styled";

// TODO: Use GQL type
interface IProps {
  pictures: any[];
}

const PicturesPage: React.FC<IProps> = ({ pictures }) => {
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const { authState } = useAuth();

  const closeDrawer = () => setOpen(false);

  const handleSubmit = () => {
    console.log(files);
    console.log("Upload & process files here, then notify success/error");
  };

  return (
    <PageContainer>
      <Title variant="h3" textAlign="center">
        Life in Pictures
      </Title>
      <ContentContainer>
        {/* TODO: Use Masonry and GalleryCard to display the gallery */}
        {pictures.length > 0 ? null : (
          <NoPicturesView>
            {authState?.isAuthenticatedInCurrentContext ? (
              <Button onClick={() => setOpen(!open)}>Add images</Button>
            ) : null}
          </NoPicturesView>
        )}
        <Drawer open={open} onClose={closeDrawer}>
          <DrawerContent
            title="Edit Life in Pictures"
            submitButtonLabel="Save Photos"
            isSubmitButtonDisabled={files.length === 0}
            onClose={closeDrawer}
            submitButtonProps={{
              onClick: handleSubmit,
            }}
          >
            <StyledDropzone
              files={files}
              onChange={setFiles}
              dropzoneOptions={{
                multiple: true,
                accept: ["image/*", "video/mp4"],
              }}
              description={
                <ul>
                  <li>
                    <Typography>
                      Photos can be in jpg, png or tiff format, no more than 5
                      MB in size.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Videos should be no more than 1-2 minutes in length.
                    </Typography>
                  </li>
                </ul>
              }
            />
          </DrawerContent>
        </Drawer>
      </ContentContainer>
    </PageContainer>
  );
};

export default PicturesPage;
