import styled from "@/components/contexts/ThemeProvider/styled";
import {
    LinearGradientContainer, serifFontFamily,
    Typography
} from "@gannettdigital/community-hub-components";

export const Container = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2.5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),

  "&>:not(style)": {
    marginTop: theme.spacing(2.5)
  },

  "&>div:first-of-type": {
    marginTop: 0
  },

  [theme.breakpoints.up("sm")]: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5)
  },

  [theme.breakpoints.up("md")]: {
    marginBottom: theme.spacing(5),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),

    "&>:not(style)": {
      marginTop: theme.spacing(5)
    }
  }
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontFamily: serifFontFamily,
  fontSize: 24,
  textAlign: "center",
  marginTop: theme.spacing(2.5),
  marginBottom: theme.spacing(2.5),

  [theme.breakpoints.up("md")]: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5)
  },

  [theme.breakpoints.up("lg")]: {
    fontSize: 34
  }
}));

export const ImageFooter = styled(LinearGradientContainer)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  zIndex: 0,

  "&::first-of-type": {
    zIndex: 0
  },

  // White ground underneath photo
  "&::after": {
    content: '""',
    display: "block",
    width: "100%",
    height: "155px",
    marginTop: "-115px",
    backgroundColor: theme.palette.all.white,
    borderTop: `2px solid ${theme.palette.all.gray7}`,
    zIndex: -1,

    [theme.breakpoints.up("sm")]: {
      height: "110px"
    }
  }
}));
