// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IGetDisplayByCityQueryVariables = Types.Exact<{
  after?: Types.Maybe<Types.Scalars['timestamp']>;
  term?: Types.Maybe<Types.Scalars['String']>;
  before?: Types.Maybe<Types.Scalars['timestamp']>;
  id: Types.Scalars['uuid'];
  offset?: Types.Maybe<Types.Scalars['Int']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type IGetDisplayByCityQuery = (
  { __typename?: 'query_root' }
  & { display_obituary: Array<(
    { __typename?: 'display_obituary' }
    & Pick<Types.IDisplay_Obituary, 'city' | 'last_name' | 'state'>
    & { publish_date: Types.IDisplay_Obituary['source_published_at'], slug: Types.IDisplay_Obituary['source_slug'] }
  )> }
);


export const GetDisplayByCityDocument = gql`
    query getDisplayByCity($after: timestamp, $term: String = null, $before: timestamp, $id: uuid!, $offset: Int, $limit: Int) {
  display_obituary(
    limit: $limit
    offset: $offset
    where: {display_obituary_publications: {publication_id: {_eq: $id}}, city: {_ilike: $term, _is_null: false, _gt: "1"}, source_published_at: {_lte: $before, _gte: $after}}
    order_by: [{city: asc}, {source_published_at: desc_nulls_last}, {last_name: asc}]
  ) {
    city
    last_name
    publish_date: source_published_at
    slug: source_slug
    state
  }
}
    `;

/**
 * __useGetDisplayByCityQuery__
 *
 * To run a query within a React component, call `useGetDisplayByCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisplayByCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisplayByCityQuery({
 *   variables: {
 *      after: // value for 'after'
 *      term: // value for 'term'
 *      before: // value for 'before'
 *      id: // value for 'id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDisplayByCityQuery(baseOptions: Apollo.QueryHookOptions<IGetDisplayByCityQuery, IGetDisplayByCityQueryVariables>) {
        return Apollo.useQuery<IGetDisplayByCityQuery, IGetDisplayByCityQueryVariables>(GetDisplayByCityDocument, baseOptions);
      }
export function useGetDisplayByCityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetDisplayByCityQuery, IGetDisplayByCityQueryVariables>) {
          return Apollo.useLazyQuery<IGetDisplayByCityQuery, IGetDisplayByCityQueryVariables>(GetDisplayByCityDocument, baseOptions);
        }
export type GetDisplayByCityQueryHookResult = ReturnType<typeof useGetDisplayByCityQuery>;
export type GetDisplayByCityLazyQueryHookResult = ReturnType<typeof useGetDisplayByCityLazyQuery>;
export type GetDisplayByCityQueryResult = Apollo.QueryResult<IGetDisplayByCityQuery, IGetDisplayByCityQueryVariables>;