import { ContentWell } from "@/components/molecules";
import { ObituaryDrawer } from "@/components/organisms";
import { IFromSalesOrderFragment } from "@/graphql/fragments/__generated__/fromSalesOrder.types";
import { IUserCreatedObituaryFragment } from "@/graphql/fragments/__generated__/userCreatedObituary.types";
import { useInsertExternalLinkMutation } from "@/graphql/mutations/__generated__/insertExternalLink.types";
import { useInsertObituaryMutation } from "@/graphql/mutations/__generated__/insertObituary.types";
import {
  IExternal_Link_Type_Enum
} from "@/graphql/__generated__/graphql.types";
import { formatToDBDate } from "@/helpers/dates";
import { usePublicationId } from "@/hooks/useAppState";
import useImageFunctions from "@/hooks/useImageFunctions";
import logger from "@/logging/client";
import {
  useSnackbar
} from "@gannettdigital/community-hub-components";
import { Box } from "@mui/material";
import getConfig from "next/config";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Container,
  ImageContainer,
  LightBlueButton,
  SubTitle,
  Title,
  Wrapper
} from "./CreateObituaryPage.styled";

const { publicRuntimeConfig } = getConfig();

interface IProps {
  loading?: boolean;
}

const CreateObituaryPage: React.FC<IProps> = ({ loading }) => {
  // possibly listen to route changes so we can handle the "user clicks away, confirm they want to discard values"
  // rather than keeping the open in state;
  const [open, setOpen] = useState(true);
  const { showSnackbar } = useSnackbar();
  const publicationId = usePublicationId();
  const { createImages, error } = useImageFunctions();
  const [insertObituary] = useInsertObituaryMutation();
  const [insertExternalLink] = useInsertExternalLinkMutation();

  // create obit, then if no error and gofundmelink
  // create external_link, then if no error
  // display success message
  // on error display generic error message and log actual message
  const handleSubmit = async (
    obituary: IUserCreatedObituaryFragment,
    fromSalesOrder: IFromSalesOrderFragment
  ) => {
    try {
      if (!obituary.slug) {
        obituary.slug = uuidv4();
      }

      const response = await insertObituary({
        variables: {
          slug: obituary.slug,
          first_name: obituary.life_stories[0].first_name,
          middle_name: obituary.life_stories[0].middle_name,
          last_name: obituary.life_stories[0].last_name,
          suffix: obituary.life_stories[0].suffix,
          birth: formatToDBDate(obituary.life_stories[0].birth),
          passing: formatToDBDate(obituary.life_stories[0].passing),
          story: obituary.life_stories[0].story,
          city: obituary.life_story_locations[0].city,
          state: obituary.life_story_locations[0].state,
          country: obituary.life_story_locations[0].country,
          publication_id: publicationId,

          print_start: obituary.from_sales_orders[0].print_start,
          print_end: obituary.from_sales_orders[0].print_end,
          contact_firstname: fromSalesOrder.contact_firstname,
          contact_lastname: fromSalesOrder.contact_lastname,
          billing_address: fromSalesOrder.billing_address,
          billing_city: fromSalesOrder.billing_city,
          billing_state: fromSalesOrder.billing_state,
          billing_zip: fromSalesOrder.billing_zip,
          billing_phone: fromSalesOrder.billing_phone,
          billing_email: fromSalesOrder.billing_email,
          verification_provider: fromSalesOrder.verification_provider,
          verification_contact: fromSalesOrder.verification_contact,
          verification_phone: fromSalesOrder.verification_phone,

          obituary_tags: []
        }
      });

      if (response.errors) {
        throw response.errors[0];
      }

      if (!response || !response.data || !response.data.insert_obituary_one) {
        throw new Error("Could not get inserted obit data");
      }

      const obitId = response.data.insert_obituary_one.id;
      const obitSlug = response.data.insert_obituary_one.slug;

      // separate because we might not have anything to insert
      if (obituary.external_links[0].uri) {
        const insertExternalLinkResponse = await insertExternalLink({
          variables: {
            obituary_id: obitId,
            type: IExternal_Link_Type_Enum.Gofundme,
            uri: obituary.external_links[0].uri
          }
        });

        if (insertExternalLinkResponse.errors) {
          throw insertExternalLinkResponse.errors[0];
        }
      }

      try {
        await createImages(obitId, obituary.images);
      } catch (error: any) {
        console.error(`could not upload images: ${error.message}`);
      }

      // send mail to all interested sales teams
      await fetch(
        `${publicRuntimeConfig.BASE_PATH}/api/sendMailToSales/${obitSlug}`
      );

      showSnackbar({
        title: "Thank you!",
        message: "The obituary has been saved. We will contact you soon!"
      });

      setOpen(false);
    } catch (error: any) {
      logger.error(`Could not create obituary`, error);

      showSnackbar({
        title: "An error has occurred!",
        message: "You might not have permissions for this operation!",
        autoHideDuration: 3000
      });
    }
  };

  return (
    <>
      <ContentWell>
        <Box px={{ xs: 3, sm: 7.5 }} py={{ xs: 7.5 }} pb={{ xs: 0 }}>
          <Container>
            <Title variant="h3">Life Story</Title>
            <SubTitle variant="body1">
              An obituary helps tell your loved one&apos;s life story. Honor
              their memory, reflect on what matters, and share how they made a
              difference through a lasting tribute.
            </SubTitle>
            <Wrapper>
              <LightBlueButton
                onClick={() => {
                  setOpen(true);
                }}
              >
                Get Started
              </LightBlueButton>
              <ImageContainer>
                <picture>
                  <img
                    src={`${publicRuntimeConfig.assetsPrefix}/static/images/woman-heart.svg`}
                    alt="Image of a woman holding a flower"
                    width="100%"
                    height="350px"
                  />
                </picture>
              </ImageContainer>
            </Wrapper>
          </Container>
        </Box>
      </ContentWell>
      <ObituaryDrawer
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={handleSubmit}
        loadingData={loading}
      />
    </>
  );
};

export default CreateObituaryPage;
