import styled from "@/components/contexts/ThemeProvider/styled";
import Box from "@mui/material/Box";

export const HighImpactBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: theme.spacing(3),

  "& iframe": {
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(3, 0)
    }
  }
}));

export const LeaderboardBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  marginBottom: theme.spacing(3)
}));
