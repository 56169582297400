import {
  Typography,
  styled,
  theme,
} from "@gannettdigital/community-hub-components";
import { Box } from "@mui/material";
import React, { ReactNode } from "react";

export interface CTAProps {
  renderIcon: () => React.ReactNode;
  title: ReactNode;
  description?: ReactNode;
  onClick?: () => void;
  children?: React.ReactNode;
}

const CTA = ({
  renderIcon,
  title,
  description,
  children,
  onClick,
}: CTAProps) => {
  return (
    <div>
      <Container
        sx={{
          cursor: onClick ? "pointer" : "auto",
        }}
        onClick={onClick}
      >
        <Box
          className="icon-container"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {renderIcon()}
        </Box>

        <div>
          <Typography
            variant="h4"
            height={{ xs: "30px", sm: "40px", md: "auto" }}
            textAlign={{ xs: "center", md: "left" }}
            fontSize={{ xs: "12px", sm: "16px" }}
            lineHeight={{ xs: "15px", sm: "20px" }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color={theme.palette.all.gray4}
            textAlign={{ xs: "center", md: "left" }}
            display={{ xs: "none", md: "block" }}
          >
            {description}
          </Typography>
        </div>
      </Container>

      {/* This is the place for tooltip popup right under the CTA */}
      {children}
    </div>
  );
};

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(1),
  width: "70px",

  [theme.breakpoints.up("sm")]: {
    width: "115px",
    justifyContent: "start",
  },

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
    width: "100%",
  },
}));

export default CTA;
