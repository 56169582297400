import styled from "@/components/contexts/ThemeProvider/styled";

const MobileOnly = styled("div")(({ theme }) => ({
  display: "block",

  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

export default MobileOnly;
