import {
  NoServicesView,
  ServicesDrawer,
  ServicesLayout,
} from "@/components/organisms";
import { CardContainer } from "@/components/organisms/ServicesLayout/ServicesLayout.styled";
import { IObituaryBannerDataFragment } from "@/graphql/fragments/__generated__/obituaryBannerData.types";
import { useGetServicesByObitSlugQuery } from "@/graphql/queries/__generated__/getServicesByObitSlug.types";
import useAuth from "@/hooks/useAuth";
import { DETAIL_PAGE_TYPE, useAnalytics } from "@/services/analyticsService";
import {
  Button,
  LoadingServiceCard,
} from "@gannettdigital/community-hub-components";
import sortBy from "lodash/sortBy";
import "mapbox-gl/dist/mapbox-gl.css";
import { useEffect, useState } from "react";
import Error404 from "../../../../pages/404";
import {
  ContentContainer,
  PageContainer,
  Subtitle,
  SubtitleButton,
  Title,
} from "./ServicesPage.styled";

interface IProps {
  obit: IObituaryBannerDataFragment;
}

const ServicesPage: React.FC<IProps> = ({ obit }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { trackEvent } = useAnalytics();
  const { authState } = useAuth();

  const toggleDrawer = () => setOpenDrawer((prev) => !prev);

  const { data, loading, error, refetch } = useGetServicesByObitSlugQuery({
    variables: {
      id: obit.id,
    },
  });

  useEffect(() => {
    if (authState) {
      refetch();
    }
  }, [authState, refetch]);
  
  const canEdit = data?.obituary_by_pk?.is_owner || false;
  const services =
    sortBy(data?.service, (service) =>
      service.service_durations.length > 0
        ? service.service_durations[0].begin
        : service.title
    ) || [];

  if (error) {
    return <Error404 />;
  }

  const Content = () => {
    if (loading) {
      return (
        <CardContainer>
          <LoadingServiceCard />
        </CardContainer>
      );
    }

    if (services.length > 0) {
      return (
        <ServicesLayout services={services} obit={obit}>
          {canEdit ? (
            <Subtitle>
              To edit this information,&nbsp;
              <SubtitleButton onClick={toggleDrawer}>click here</SubtitleButton>
              .
            </Subtitle>
          ) : null}
        </ServicesLayout>
      );
    }

    return (
      <NoServicesView>
        {canEdit ? (
          <Button
            onClick={() => {
              trackEvent({
                action: "services|add",
                pageType: DETAIL_PAGE_TYPE,
              });
              toggleDrawer();
            }}
          >
            Add information
          </Button>
        ) : null}
      </NoServicesView>
    );
  };

  return (
    <PageContainer>
      <Title variant="h3" textAlign="center">
        Service Locations &#38; Times
      </Title>
      <ContentContainer>
        <Content />
      </ContentContainer>
      <ServicesDrawer
        open={openDrawer}
        defaultValues={services}
        onClose={toggleDrawer}
        obitId={obit.id}
      />
    </PageContainer>
  );
};

export default ServicesPage;
