import { Howl, Howler, HowlOptions } from "howler";
import { useCallback, useEffect, useState } from "react";

declare global {
  interface Window {
    Howler: typeof Howler;
  }
}

Howler.autoUnlock = false;

enum AUDIO_STATE {
  load = "load",
  loaded = "loaded",
  pause = "pause",
  play = "play",
  stop = "stop",
  stopped = "stopped",
}

export interface UsePlayerProps {
  /**
   * The URL of the audio
   */
  src: string;
  /**
   * Options, passed to the Howl instance
   */
  options?: Partial<HowlOptions>;
  /**
   * The headers for the XHR request that howler.js sends for the audio, e.g. x-api-key
   */
  headers?: {
    [key: string]: string;
  };
}

export const usePlayer = ({ src, options, headers }: UsePlayerProps) => {
  const [sound, setSound] = useState<null | Howl>(null);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [state, setState] = useState<null | keyof typeof AUDIO_STATE>(null);
  const [hasLoaded, setHasLoaded] = useState(false);

  const loadAndPlay = useCallback(() => {
    if (!src) return;

    const howl = new Howl({
      src,
      format: "mpeg",
      html5: false,
      xhr: {
        headers,
      },
      ...options,
    });

    setState(AUDIO_STATE.load);
    setSound(howl);
  }, [options, headers, src]);

  // stop playing and reset state on src change
  useEffect(() => {
    if (sound) sound.pause();

    setSound(null);
    setProgress(0);
    setDuration(0);
    setState(null);
    setHasLoaded(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, headers, src]);

  useEffect(() => {
    // unload all audio on component unmount
    return () => {
      if (window.Howler) window.Howler.unload();
    };
  }, []);

  useEffect(() => {
    if (!sound) {
      return;
    }

    sound.once("load", () => {
      setState(AUDIO_STATE.loaded);
      setHasLoaded(true);
      sound.play();
      setDuration(sound.duration());
    });

    sound.on("pause", () => {
      setState(AUDIO_STATE.pause);
    });

    function step() {
      if (!sound) {
        return;
      }

      const seek = sound.seek() || 0;
      setProgress((seek / sound.duration()) * 100 || 0);

      setTimeout(() => {
        if (sound.playing()) {
          requestAnimationFrame(step);
        }
      }, 500);
    }

    sound.on("play", () => {
      requestAnimationFrame(step);
      setState(AUDIO_STATE.play);
    });

    sound.on("stop", () => {
      setState(AUDIO_STATE.stop);
    });

    sound.on("end", () => {
      setState(AUDIO_STATE.stopped);
      setProgress(0);
    });

    return () => {
      sound.off();
    };
  }, [sound]);

  return {
    loadAndPlay,
    playing: state === "play",
    loading: state === "load",
    hasLoaded,
    duration,
    progress,
    sound,
  };
};
