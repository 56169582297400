// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { IUserCreatedObituaryFragment } from '../../fragments/__generated__/userCreatedObituary.types';
import { gql } from '@apollo/client';
import { UserCreatedObituaryFragmentDoc } from '../../fragments/__generated__/userCreatedObituary.types';
import * as Apollo from '@apollo/client';
export type IInsertObituaryMutationVariables = Types.Exact<{
  slug?: Types.Maybe<Types.Scalars['String']>;
  first_name?: Types.Maybe<Types.Scalars['String']>;
  middle_name?: Types.Maybe<Types.Scalars['String']>;
  last_name?: Types.Maybe<Types.Scalars['String']>;
  suffix?: Types.Maybe<Types.Scalars['String']>;
  birth?: Types.Maybe<Types.Scalars['date']>;
  passing?: Types.Maybe<Types.Scalars['date']>;
  story?: Types.Maybe<Types.Scalars['String']>;
  city?: Types.Maybe<Types.Scalars['String']>;
  state?: Types.Maybe<Types.Scalars['String']>;
  country?: Types.Maybe<Types.Scalars['String']>;
  publication_id: Types.Scalars['uuid'];
  print_start?: Types.Maybe<Types.Scalars['date']>;
  print_end?: Types.Maybe<Types.Scalars['date']>;
  contact_firstname?: Types.Maybe<Types.Scalars['String']>;
  contact_lastname?: Types.Maybe<Types.Scalars['String']>;
  billing_address?: Types.Maybe<Types.Scalars['String']>;
  billing_city?: Types.Maybe<Types.Scalars['String']>;
  billing_state?: Types.Maybe<Types.Scalars['String']>;
  billing_zip?: Types.Maybe<Types.Scalars['String']>;
  billing_phone?: Types.Maybe<Types.Scalars['String']>;
  billing_email?: Types.Maybe<Types.Scalars['String']>;
  verification_provider?: Types.Maybe<Types.Scalars['String']>;
  verification_contact?: Types.Maybe<Types.Scalars['String']>;
  verification_phone?: Types.Maybe<Types.Scalars['String']>;
  obituary_tags: Array<Types.IObituary_Tag_Insert_Input> | Types.IObituary_Tag_Insert_Input;
}>;


export type IInsertObituaryMutation = (
  { __typename?: 'mutation_root' }
  & { insert_obituary_one?: Types.Maybe<(
    { __typename?: 'obituary' }
    & IUserCreatedObituaryFragment
  )> }
);


export const InsertObituaryDocument = gql`
    mutation InsertObituary($slug: String, $first_name: String, $middle_name: String, $last_name: String, $suffix: String, $birth: date, $passing: date, $story: String, $city: String, $state: String, $country: String, $publication_id: uuid!, $print_start: date, $print_end: date, $contact_firstname: String, $contact_lastname: String, $billing_address: String, $billing_city: String, $billing_state: String, $billing_zip: String, $billing_phone: String, $billing_email: String, $verification_provider: String, $verification_contact: String, $verification_phone: String, $obituary_tags: [obituary_tag_insert_input!]!) {
  insert_obituary_one(
    object: {slug: $slug, life_stories: {data: {first_name: $first_name, middle_name: $middle_name, last_name: $last_name, suffix: $suffix, birth: $birth, passing: $passing, story: $story}}, life_story_locations: {data: {city: $city, state: $state, country: $country, notes: "", type: hometown}}, from_sales_orders: {data: {print_start: $print_start, print_end: $print_end, contact_firstname: $contact_firstname, contact_lastname: $contact_lastname, billing_address: $billing_address, billing_city: $billing_city, billing_state: $billing_state, billing_zip: $billing_zip, billing_phone: $billing_phone, billing_email: $billing_email, email_sent_to_sales: false, verification_provider: $verification_provider, verification_contact: $verification_contact, verification_phone: $verification_phone, have_accepted_terms_and_service: true}}, obituary_publications: {data: {publication_id: $publication_id}}, obituary_tags: {data: $obituary_tags}}
  ) {
    ...UserCreatedObituary
  }
}
    ${UserCreatedObituaryFragmentDoc}`;
export type IInsertObituaryMutationFn = Apollo.MutationFunction<IInsertObituaryMutation, IInsertObituaryMutationVariables>;

/**
 * __useInsertObituaryMutation__
 *
 * To run a mutation, you first call `useInsertObituaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertObituaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertObituaryMutation, { data, loading, error }] = useInsertObituaryMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      first_name: // value for 'first_name'
 *      middle_name: // value for 'middle_name'
 *      last_name: // value for 'last_name'
 *      suffix: // value for 'suffix'
 *      birth: // value for 'birth'
 *      passing: // value for 'passing'
 *      story: // value for 'story'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      country: // value for 'country'
 *      publication_id: // value for 'publication_id'
 *      print_start: // value for 'print_start'
 *      print_end: // value for 'print_end'
 *      contact_firstname: // value for 'contact_firstname'
 *      contact_lastname: // value for 'contact_lastname'
 *      billing_address: // value for 'billing_address'
 *      billing_city: // value for 'billing_city'
 *      billing_state: // value for 'billing_state'
 *      billing_zip: // value for 'billing_zip'
 *      billing_phone: // value for 'billing_phone'
 *      billing_email: // value for 'billing_email'
 *      verification_provider: // value for 'verification_provider'
 *      verification_contact: // value for 'verification_contact'
 *      verification_phone: // value for 'verification_phone'
 *      obituary_tags: // value for 'obituary_tags'
 *   },
 * });
 */
export function useInsertObituaryMutation(baseOptions?: Apollo.MutationHookOptions<IInsertObituaryMutation, IInsertObituaryMutationVariables>) {
        return Apollo.useMutation<IInsertObituaryMutation, IInsertObituaryMutationVariables>(InsertObituaryDocument, baseOptions);
      }
export type InsertObituaryMutationHookResult = ReturnType<typeof useInsertObituaryMutation>;
export type InsertObituaryMutationResult = Apollo.MutationResult<IInsertObituaryMutation>;
export type InsertObituaryMutationOptions = Apollo.BaseMutationOptions<IInsertObituaryMutation, IInsertObituaryMutationVariables>;