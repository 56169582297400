import { ContentWell } from "@/components/molecules";
import ErrorPage from "@/components/pages/ErrorPage/ErrorPage";

const Error404 = () => {
  return (
    <ContentWell>
      <ErrorPage statusCode={404} />
    </ContentWell>
  );
};

export default Error404;
