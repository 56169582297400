import styled from "@/components/contexts/ThemeProvider/styled";
import { Colors } from "@/components/contexts/ThemeProvider/theme";
import {
    Dropzone,
    Typography,
    unifySansRgFontFamily
} from "@gannettdigital/community-hub-components";

export const PageContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),

  [theme.breakpoints.up("sm")]: {
    marginTop: theme.spacing(4)
  }
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontStyle: "italic",
  lineHeight: "26px",
  marginBottom: theme.spacing(1),
  fontSize: 22,

  [theme.breakpoints.up("sm")]: {
    marginBottom: theme.spacing(2),
    fontSize: 32
  }
}));

export const ContentContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  flexDirection: "column"
});

export const StyledDropzone = styled(Dropzone)({
  margin: "30px",
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  flexDirection: "column",
  height: "180px"
});

export const StyledDropText = styled(Typography)({
  color: Colors.blue5,
  fontFamily: unifySansRgFontFamily,
  fontWeight: 700
});
