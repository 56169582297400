import {
  PlayIcon,
  RoundedPauseIcon,
  styled,
} from "@gannettdigital/community-hub-components";
import { CircularProgress, CircularProgressProps } from "@mui/material";
import React from "react";
import CTA, { CTAProps } from "../CTA";
import { UsePlayerProps, usePlayer } from "./usePlayer";

export type AudioPlayerProps = UsePlayerProps &
  Omit<CTAProps, "renderIcon" | "onClick"> & {
    circularProgressProps?: CircularProgressProps;
  };

const LoaderWrapper = styled("div")({
  width: 35,
  height: 35,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  options,
  headers,
  src,
  circularProgressProps,
  ...rest
}) => {
  const { loadAndPlay, hasLoaded, playing, loading, sound } = usePlayer({
    options,
    headers,
    src,
  });

  return (
    <CTA
      {...rest}
      renderIcon={() =>
        loading ? (
          <LoaderWrapper>
            <CircularProgress size={25} {...circularProgressProps} />
          </LoaderWrapper>
        ) : playing ? (
          <RoundedPauseIcon fontSize="large" />
        ) : (
          <PlayIcon fontSize="large" />
        )
      }
      onClick={() => {
        if (loading) {
          // nothing
        } else if (!hasLoaded) {
          loadAndPlay();
        } else if (playing) {
          sound?.pause();
        } else {
          sound?.play();
        }
      }}
    />
  );
};

export default AudioPlayer;
