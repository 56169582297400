export const navigatorCanShare = () => {
  return (
    typeof navigator !== "undefined" && navigator !== null && navigator.canShare
  );
};

export interface INativeShareData {
  url?: string;
  title: string;
  text: string;
  onError: (error: any) => void;
}

export const nativeShare: (data: INativeShareData) => void = async ({ url, title, text, onError }) => {
  try {
    await navigator.share({
      url,
      title,
      text
    });
  } catch (err) {
    onError(err);
  }
};

export const getCurrentURL = () => {
  if (typeof window !== "undefined" && window !== null) {
    return window.location.href.split("?")[0];
  }
};
