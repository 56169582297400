import styled from "@/components/contexts/ThemeProvider/styled";
import { parseHyperlinkURLs } from "@/helpers/urls";
import { serifFontFamily } from "@gannettdigital/community-hub-components";
import { Typography } from "@mui/material";

interface Iprops {
  paragraphs: string[];
}

export const Paragraphs = ({ paragraphs }: Iprops) => {
  if (!paragraphs) {
    return null;
  }

  return (
    <>
      {paragraphs.map((paragraph, idx) => (
        <CustomParagraph
          // Render only, `index` as key is acceptable in this case
          key={idx}
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: cleanParagraph(paragraph),
          }}
        />
      ))}
    </>
  );
};

const CustomParagraph = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-body1": {
    fontFamily: serifFontFamily,
    fontSize: 16,
    lineHeight: 1.5,
    marginBottom: 16,
    "& > a": {
      color: theme.palette.primary.main,
      wordBreak: "break-word",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
      marginLeft: 0,
    },
  },
}));

/**
 * Replace all `&nbsp;`, since they can can cause issues with other
 * text processing. Additionally, convert all URLs into hyperlinks.
 */
const cleanParagraph = (paragraph: string) =>
  parseHyperlinkURLs(paragraph.replaceAll(/&nbsp;/gi, " "));
