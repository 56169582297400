import CTA from "@/components/common/CTA";
import styled from "@/components/contexts/ThemeProvider/styled";
import SendFlowersContent from "@/components/molecules/SlugPageSendFlowersContent/SlugPageSendFlowersContent";
import { ILocationFragment } from "@/graphql/fragments/__generated__/location.types";
import { IObituaryFragment } from "@/graphql/fragments/__generated__/obituary.types";
import { formatFtdCardValues } from "@/helpers/obituary";
import { DETAIL_PAGE_TYPE, useAnalytics } from "@/services/analyticsService";
import {
  FlowerIcon,
  Tooltip,
  v2BaseProdFtdUrl,
} from "@gannettdigital/community-hub-components";
import { ClickAwayListener, StackProps } from "@mui/material";
import { useState } from "react";

interface CTASendFlowersProps {
  obituary: IObituaryFragment;
  funeralHome?: ILocationFragment;
  isArchiveObit?: boolean;
  width?: StackProps["width"];
}

export const CTASendFlowers = ({
  obituary,
  funeralHome,
  isArchiveObit,
  width,
}: CTASendFlowersProps) => {
  const { trackEvent } = useAnalytics();

  const [isOpen, setIsOpen] = useState(false);
  const ftdCardValues = formatFtdCardValues(obituary, funeralHome);

  // if we are on an archive obit, don't show the send flowers CTA
  if (isArchiveObit) {
    return null;
  }

  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  const widthProp = {
    width: {
      xs: "70px",
      sm: "100px",
      md: "auto",
    },
  };

  // if we have ftd card values, show the popup to choose a card
  if (ftdCardValues.length > 0) {
    return (
      <ClickAwayListener onClickAway={close}>
        <div>
          <CTA
            renderIcon={() => <FlowerIcon fontSize="large" htmlColor="white" />}
            title="Send flowers"
            description="Let the family know you are thinking of them"
            onClick={open}
            {...widthProp}
          >
            <Tooltip
              sx={{
                "& .MuiTooltip-tooltip": {
                  maxWidth: 350,
                  marginLeft: 0,
                },
              }}
              placement="bottom"
              onClose={close}
              open={isOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              arrow
              title={<SendFlowersContent data={ftdCardValues} />}
            >
              {/* tooltip requires at least 1 child :/ */}
              <div></div>
            </Tooltip>
          </CTA>
        </div>
      </ClickAwayListener>
    );
  }

  // Link to FTD page
  return (
    <Link href={`${v2BaseProdFtdUrl}?partnerid=Gannett`} target="_blank">
      <CTA
        renderIcon={() => <FlowerIcon fontSize="large" htmlColor="white" />}
        title="Send flowers"
        description="Let the family know you are thinking of them"
        onClick={() =>
          trackEvent({
            label: "send flowers",
            action: "action bar",
            pageType: DETAIL_PAGE_TYPE,
          })
        }
        {...widthProp}
      />
    </Link>
  );
};

const Link = styled("a")({
  color: "inherit",
  textDecoration: "none",
});
