import styled from "@/components/contexts/ThemeProvider/styled";
import type { IObituaryFragment } from "@/graphql/fragments/__generated__/obituary.types";

export const ObitExternalLink = ({
  uri,
  type,
}: IObituaryFragment["external_links"][0]) => {
  if (!uri || !type) {
    return null;
  }

  if (type === "video_legacy") {
    return (
      <IframeRoot>
        <Iframe
          title="video"
          sandbox="allow-scripts"
          allowFullScreen={true}
          src={uri}
          width="100%"
          height="100%"
        />
      </IframeRoot>
    );
  }

  // We haven't decided to support other types (other, gofundme) on this page yet
  return null;
};

const IframeRoot = styled("div")(({ theme }) => ({
  margin: `${theme.spacing(3)} 0`,
  padding: "2px",
  boxShadow: "3px 3px 10px 3px rgba(0,0,0,0.15)",
  width: "100%",
  aspectRatio: "16 / 9",

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(1),
  },
}));

const Iframe = styled("iframe")(({ theme }) => ({
  display: "block",
  border: "none",
  margin: 0,
  padding: 0,
  overflow: "hidden",
}));
