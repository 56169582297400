import styled from "@/components/contexts/ThemeProvider/styled";
import {
    Button,
    serifFontFamily,
    Typography,
    unifySansBoldFontFamily
} from "@gannettdigital/community-hub-components";

export const Container = styled("div")(({ theme }) => ({
  "& h1": {
    fontFamily: unifySansBoldFontFamily,
    fontSize: 20,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      fontSize: 24,
      marginBottom: theme.spacing(4)
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 36
    }
  },
  "& h2": {
    fontFamily: unifySansBoldFontFamily,
    fontSize: 14,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      fontSize: 16
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 20
    }
  },
  "& p": {
    fontFamily: serifFontFamily,
    fontSize: 14,
    marginBottom: 12,
    [theme.breakpoints.up("md")]: {
      fontSize: 16
    }
  },
  "& ol": {
    fontFamily: serifFontFamily,
    fontSize: 14,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      fontSize: 16
    }
  }
}));

export const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
});

export const Title = styled(Typography)({
  fontSize: 32,
  fontStyle: "italic",
  textAlign: "center",
  marginBottom: "14px"
});

export const SubTitle = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  marginBottom: "16px",

  [theme.breakpoints.up("md")]: {
    margin: "0px 60px 16px"
  }
}));

export const LightBlueButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.all.blue5,
  borderColor: theme.palette.all.blue5,
  borderRadius: "4px"
}));

export const ImageContainer = styled("div")({
  background: "linear-gradient(white, rgba(253, 236, 221))",
  width: "100vw"
});
