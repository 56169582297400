import styled from "@/components/contexts/ThemeProvider/styled";
import { Colors } from "@/components/contexts/ThemeProvider/theme";
import { IHistoricalObituaryEventFragment } from "@/graphql/fragments/__generated__/historicalObituaryEvent.types";
import { displayDate } from "@/helpers/dates";
import {
  unifySansBoldFontFamily,
  unifySansRgFontFamily,
} from "@gannettdigital/community-hub-components";
import { Grid, Typography } from "@mui/material";
import { isAfter, isValid } from "date-fns";
import React from "react";

interface IProps {
  events: IHistoricalObituaryEventFragment[];
}

const EventsCom = ({ events }: IProps) => {
  if (events.length === 0) {
    return null;
  }

  return (
    <Container>
      <Typography variant="h3">Service Information</Typography>
      <Grid container spacing={4}>
        {events.map((event) => (
          <Grid item key={event.id} xs={12} md={6}>
            <Typography variant="h4">{event.type}</Typography>
            {event.location && (
              <Typography variant="h5">{event.location}</Typography>
            )}
            <EventDateTime>
              {/* additional date/time checks for iPublish data */}
              {validEventDate(event.date) && (
                <>
                  {displayDate(event.date)}
                  {event.time && " at "}
                </>
              )}
              {event.time}
            </EventDateTime>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export const Events = React.memo(EventsCom);

const validEventDate = (date: Date | undefined) => {
  if (date === undefined) {
    return false;
  }

  const d = new Date(date);
  return isValid(d) && isAfter(d, new Date(1989, 11, 31));
};

const EventDateTime = styled("div")(({ theme }) => ({
  fontFamily: unifySansRgFontFamily,
  fontWeight: "bold",
  fontSize: 12,
  marginBottom: theme.spacing(2),
}));

const Container = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(4),
  "& h3": {
    fontFamily: unifySansBoldFontFamily,
    fontSize: 24,
    marginBottom: 28,
    [theme.breakpoints.up("md")]: {
      fontSize: 24,
      marginBottom: theme.spacing(4),
      marginLeft: 0,
    },
  },
  "& h4": {
    color: Colors.blue5,
    fontFamily: unifySansRgFontFamily,
    fontSize: 14,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    textTransform: "uppercase",
  },
  "& h5": {
    fontFamily: unifySansBoldFontFamily,
    fontSize: 16,
    marginBottom: theme.spacing(1),
  },
  "& p": {
    marginBottom: theme.spacing(1),
  },
}));
