import { ImageRender } from "@/components/common/ImageRender";
import styled from "@/components/contexts/ThemeProvider/styled";
import { formatImageURL } from "@/helpers/urls";
import { Grid } from "@mui/material";

interface FuneralHomeLogosProps {
  logos: string[];
  siteCode: string;
}

export const FuneralHomeLogos = ({
  siteCode,
  logos,
}: FuneralHomeLogosProps) => {
  if (logos.length === 0) {
    return null;
  }

  return (
    <FuneralHomeLogoGrid
      container
      wrap="wrap"
      justifyContent={{ xs: "space-around", md: "unset" }}
      alignItems={{ xs: "center", md: "left" }}
      alignContent={{ xs: "center", md: "left" }}
    >
      {logos.map((logo) => (
        <ImageRender
          key={logo}
          src={getLogoSrc(siteCode, logo)}
          alt="funeral-home-logo"
          width={220}
          height={240}
        />
      ))}
    </FuneralHomeLogoGrid>
  );
};

// iPublish logos contain "+" instead of encoded space
const getLogoSrc = (siteCode: string, logo: string) =>
  formatImageURL(`/logos/${siteCode}/${logo}`).replace(/\+/g, "%20");

const FuneralHomeLogoGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(6),

  [theme.breakpoints.up("sm")]: {
    "& > *": {
      marginRight: theme.spacing(3),
    },
  },
}));
