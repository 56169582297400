import {
  ContentWell,
  ManageObituariesList,
  TopNav,
} from "@/components/molecules";
import getConfig from "next/config";
import { Container, ImageFooter, Title } from "./ManageObituariesPage.styled";

const { publicRuntimeConfig } = getConfig();

export const ManageObituariesPage = () => {
  return (
    <>
      <ContentWell>
        <Container>
          <TopNav />
          <Title variant="h1">Manage Obituaries</Title>
          <ManageObituariesList />
        </Container>
      </ContentWell>

      <ImageFooter>
        <picture>
          <img
            alt=""
            src={`${publicRuntimeConfig.assetsPrefix}/static/images/woman-in-dream-world.svg`}
          />
        </picture>
      </ImageFooter>
    </>
  );
};

export default ManageObituariesPage;
