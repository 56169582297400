import styled from "@/components/contexts/ThemeProvider/styled";

const NonMobileOnly = styled("div")(({ theme }) => ({
  display: "none",

  [theme.breakpoints.up("md")]: {
    display: "block",
  },
}));

export default NonMobileOnly;
