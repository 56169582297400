import AudioPlayer from "@/components/common/AudioPlayer";
import theme from "@/components/contexts/ThemeProvider/theme";
import { IObituaryFragment } from "@/graphql/fragments/__generated__/obituary.types";
import { DETAIL_PAGE_TYPE, useAnalytics } from "@/services/analyticsService";

import useMediaQuery from "@mui/material/useMediaQuery";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

interface IProps {
  obituary: IObituaryFragment;
}

export const CTAListenStory = ({ obituary }: IProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { trackEvent } = useAnalytics();

  const ttsSrc = `${publicRuntimeConfig.textToSpeechEndpoint}/historical/${obituary.id}`;

  return (
    <AudioPlayer
      src={ttsSrc}
      options={{
        onplay: () =>
          trackEvent({
            action: "action bar",
            label: "audio|play",
            pageType: DETAIL_PAGE_TYPE,
          }),
        onpause: () =>
          trackEvent({
            action: "action bar",
            label: "audio|pause",
            pageType: DETAIL_PAGE_TYPE,
          }),
      }}
      headers={{
        "x-api-key": publicRuntimeConfig.textToSpeechApiKey,
      }}
      title={isMobile ? "Listen to obituary" : <>Listen to this&nbsp;story</>}
      description="Hear your loved one's obituary"
    />
  );
};
