import { ILocationFragment } from "@/graphql/fragments/__generated__/location.types";
import { IObituaryFragment } from "@/graphql/fragments/__generated__/obituary.types";
import { Stack } from "@mui/material";
import { CTAListenStory } from "./CTAListenStory";
import { CTAPlantTree } from "./CTAPlantTree";
import { CTASendFlowers } from "./CTASendFlowers";
import { CTAShareObituary } from "./CTAShareObituary";
import { CTAViewMemoryBoard } from "./CTAViewMemoryBoard";

interface CTABoxMobileProps {
  obituary: IObituaryFragment;
  funeralHome?: ILocationFragment;
  treePlantingUrl?: string;
  hasMemories?: boolean;
  isArchiveObit?: boolean;
}

export const CTABoxMobile = ({
  obituary,
  treePlantingUrl,
  hasMemories,
  funeralHome,
  isArchiveObit,
}: CTABoxMobileProps) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-evenly"
      width="100%"
      px={2}
      m={{ xs: "0 0 25px", sm: "15px 0 25px" }}
    >
      <CTAViewMemoryBoard obituary={obituary} hasMemories={hasMemories} />
      <CTAShareObituary obituary={obituary} />
      <CTAListenStory obituary={obituary} />
      <CTAPlantTree treePlantingUrl={treePlantingUrl} />
      <CTASendFlowers
        obituary={obituary}
        funeralHome={funeralHome}
        isArchiveObit={isArchiveObit}
      />
    </Stack>
  );
};
