import { ImageRender } from "@/components/common/ImageRender";
import styled from "@/components/contexts/ThemeProvider/styled";
import { IImageFragment } from "@/graphql/fragments/__generated__/image.types";
import { formatImageURLToFastlyURL } from "@/helpers/urls";
import { Box } from "@mui/material";

interface SlugPagePhotosProps {
  alt?: string;
  photos?: IImageFragment[];
}

export const Photos = ({ photos, alt }: SlugPagePhotosProps) => {
  if (!photos || !photos.length) {
    return null;
  }

  return (
    <ImageList>
      {photos.map((photo, index) => (
        <PhotoItem
          key={photo.id}
          src={formatImageURLToFastlyURL(photo.uri, 360)}
          alt={photos.length > 1 ? `Photo ${index + 1} - ${alt}` : `${alt}`}
        />
      ))}
    </ImageList>
  );
};

const PhotoItem = ({ src, alt }: { src: string; alt: string }) => {
  return (
    <Box position="relative" overflow="hidden">
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        style={{ filter: "blur(30px)", opacity: 0.3 }}
      >
        <ImageRender
          alt={alt}
          src={src}
          width={360}
          height={450}
          objectPosition="center"
          objectFit="cover"
        />
      </Box>
      <ImageRender
        alt={alt}
        src={src}
        width={360}
        height={450}
        objectPosition="center"
      />
    </Box>
  );
};

const ImageList = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
});
